import {Button, Card, Col, Container, Row} from "react-bootstrap";
import PageHeading from "../../utils/PageHeading";
import ElevatedCard from "../../utils/ElevatedCard";
import ImageZoom from "../../utils/ImageZoom";
import routeMap from "../../../resources/BirminghamRoute.jpeg"

const BirminghamEventPage = () => {
    return (
        <Container fluid style={{fontFamily: "sans-serif", flexGrow: "1", flexShrink: "0", flexBasis: "auto"}}>
            <Row>
                <PageHeading>Get Involved</PageHeading>
            </Row>
            <Row className="mt-4">
                <Row className="mb-3 py-4 mx-0 justify-content-center">
                    <Col className="mb-3 justify-content-center align-items-center text-center" md={9} lg={8} xl={7}>
                        <Row>
                            <h2 className="fs-2 fw-bold">The Birmingham 24 Hour Run Against Homelessness 2024</h2>
                        </Row>
                        <Row className="fw-bold justify-content-center">
                            <Col sm={8} md={6} className="mt-3 justify-content-center">
                                <ElevatedCard colour="#FFA500" elevation={4}>
                                    <Card.Body><i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;{"18:00 Friday 1st November - 18:00 Saturday 2nd November"}</Card.Body>
                                </ElevatedCard>
                            </Col>
                            <Col sm={8} md={6} className="mt-3">
                                <ElevatedCard colour="#FFCC00" elevation={4}>
                                    <Card.Body><i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{"Location: St Basils Head Office, 71-75 Allcock St, Deritend, Birmingham B9 4DY."}</Card.Body>
                                </ElevatedCard>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: 12, paddingRight: 12}}>
                            <Button className="mt-4 fs-1 w-40 p-3" as="a" href="https://www.givewheel.com/fundraising/2399/bham-24hr-run/" variant="primary" style={{padding: "8px 12px", borderRadius: "5px",
                                backgroundColor: "#9e0b85",
                                textDecoration: "none",
                                color: "white",
                                fontFamily: "Versa Versa",
                            }}>
                                Donate
                            </Button>
                        </Row>
                        <Row>
                            <p className="fs-5 text-center mt-4"><b>The Birmingham 24 Hour Run Against Homelessness</b> is a mass participation group relay run that is held over 24 hours. Runners and walkers set off together in waves to complete 5km laps in the city centre of Birmingham, all to raise money for <a className="fw-bold" href="https://stbasils.org.uk/">St Basils Charity</a> and <a className="fw-bold" href="https://sifafireside.co.uk/">SIFA Fireside</a>.</p>
                        </Row>
                        <Row className="justify-content-center fs-5 align-items-center">
                            <Row><div className="divider div-transparent div-dot mb-3 mt-3"></div></Row>

                            <h2 className="mt-3">How do I take part?</h2>
                            <p>It's simple - just turn up to our host venue at any time from 18:00 on Friday 1st November, until 18:00 on Saturday 2nd November, and join the next relay wave departing on a 5km lap.</p>
                            <Col className="mb-4"><Button href="https://forms.gle/HVVwtm24Fruq2vyL9">Register your interest</Button></Col>

                            <Row><div className="divider div-transparent div-dot mb-3 mt-3"></div></Row>

                            <h2 className="mt-3">What is the format?</h2>
                            <p>Each relay group is led by one of our run leaders, who will maintain a pace that keeps everyone together for a social run. Participants can take part in as many, or as few laps as they would like. We aim to have a relay group set off once every 30 minutes - however, this will vary, due to groups running at different paces.</p>

                            <Row><div className="divider div-transparent div-dot mb-3 mt-3"></div></Row>

                            <h2 className="mt-3">Where is it held?</h2>
                            <p>The event is based at our host venue in Birmingham City Centre, with many train stations, tram stops, and bus stops close by. The route starts and finishes at <b>St Basils Head Office, 71-75 Allcock St, Deritend, Birmingham B9 4DY</b>, and takes you on a 5km lap around the city centre.</p>
                            <ImageZoom src={routeMap} alt={"map"} fullHeight={1132} fullWidth={1599}/>

                            <Row className="mt-3"><div className="divider div-transparent div-dot mb-3 mt-4"></div></Row>

                            <h2 className="mt-3 mb-3">Links</h2>
                            <Col><a href="https://www.instagram.com/bham24hourrun/"><i className="fa-brands fa-4x fa-instagram"></i></a></Col>
                            <Col>
                                <Button className="fs-1 w-40 p-3" as="a" href="https://www.givewheel.com/fundraising/2399/bham-24hr-run/" variant="primary" style={{padding: "8px 12px", borderRadius: "5px",
                                backgroundColor: "#9e0b85",
                                textDecoration: "none",
                                color: "white",
                                fontFamily: "Versa Versa",
                                }}>
                                    Donate
                                </Button>
                            </Col>
                            <Col><a href="https://linktr.ee/bham24hourrun"><svg xmlns="http://www.w3.org/2000/svg" width="70" height="80" viewBox="0 0 24 24"><path fill="currentColor" d="m13.736 5.853l4.005-4.117l2.325 2.38l-4.2 4.005h5.908v3.305h-5.937l4.229 4.108l-2.325 2.334l-5.74-5.769l-5.741 5.769l-2.325-2.325l4.229-4.108H2.226V8.121h5.909l-4.2-4.004l2.324-2.381l4.005 4.117V0h3.472zm-3.472 10.306h3.472V24h-3.472z"></path></svg></a></Col>
                        </Row>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
}

export default BirminghamEventPage;