import {Button, Card, Col, Container, Ratio, Row} from "react-bootstrap";
import ElevatedCard from "../utils/ElevatedCard";
import { Link } from "react-router-dom";

function HomePage() {

    return (
        <Container fluid>
            <Row className="landing-screen">
                <Container>
                    <Row>
                        <Col md>
                            <Button className="mt-5 fs-2 w-75" as="a" href="https://www.mcr24hourrun.co.uk/" variant="primary" size="lg">
                                Manchester
                            </Button>
                        </Col>
                        <Col md>
                            <Button className="mt-5 fs-2 w-75" as="a" href="/events/birmingham" variant="primary">
                                Birmingham
                            </Button>
                        </Col>
                    </Row>

                </Container>
            </Row>

            <Row><div className="divider div-transparent div-dot mb-3 mt-5"></div></Row>

            <Row className="my-3 py-4 justify-content-center">
                <Col className="mb-3 justify-content-center align-items-center text-center" md={9} lg={8} xl={7}>
                    <Row>
                        <h1 className="fs-2 fw-bold">The 24 Hour Run Against Homelessness</h1>
                    </Row>
                    <Row className="fw-bold justify-content-center">
                        <Col sm={8} md={6} className="mt-3 justify-content-center">
                            <ElevatedCard colour="#FFA500" elevation={4}>
                                <Card.Body><i className="fa-regular fa-calendar"></i>&nbsp;&nbsp;{"Manchester: 13th - 14th November 2024, Birmingham: 1st - 2nd November 2024"}</Card.Body>
                            </ElevatedCard>
                        </Col>
                        <Col sm={8} md={6} className="mt-3">
                            <ElevatedCard colour="#FFCC00" elevation={4}>
                                <Card.Body><i className="fa-solid fa-location-dot"></i>&nbsp;&nbsp;{"Current Locations: Manchester, Birmingham"}</Card.Body>
                            </ElevatedCard>
                        </Col>
                    </Row>
                    <Row>
                        <p className="fs-5 text-center mt-4"><b>The 24 Hour Run Against Homelessness</b> is a mass participation group relay run that is held over 24 hours. Runners and walkers set off together in waves to complete 5km laps in the city centre, all to raise money for charity. Current charities we support include the  <a className="fw-bold" href="https://gmmayorscharity.org.uk/">Greater Manchester Mayor's Charity</a>, <a className="fw-bold" href="https://www.instagram.com/sifafiresideofficial/">SIFA Fireside</a> and <a className="fw-bold" href="https://www.instagram.com/stbasils.charity/">St Basils Charity</a>.</p>
                    </Row>
                    <Row xs="auto" style={{justifyContent: "center"}}>
                        <Button className="mt-3 fs-3 px-3 py-2" as="a" href="/about-us" variant="primary">
                            Find out more
                        </Button>
                    </Row>             
                </Col>
            </Row>

        </Container>
    );
}

export default HomePage;