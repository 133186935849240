import BackgroundImage from "../../resources/24HourRunBackground.png";

function Timeline() {
    return (
        <div className="timeline py-4 px-0" style={{
            backgroundImage: `url(${BackgroundImage})`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundPosition: "center"
        }}>
            <div className="container-timeline left">
                <div className="content">
                    <h2>2019</h2>
                    <p>Our first event was held at the Manchester University Student's Union building. Not knowing what
                        to expect, we set an initial fundraising target of £500, and when the first two laps were
                        completed by just one runner we began to worry a little. 314 runners and £2826.84 later, our
                        worries were long gone.</p>
                </div>
            </div>
            <div className="container-timeline right">
                <div className="content">
                    <h2>2020</h2>
                    <p>The COVID-19 pandemic forced us to run the event virtually, with our run leaders keeping the
                        relay going for the full 24 hours. A total of £4270 was raised (£4974.38 inc. Gift Aid), and a
                        cumulative distance of 2721km was run by 226 participants.</p>
                </div>
            </div>
            <div className="container-timeline left">
                <div className="content">
                    <h2>2021</h2>
                    <p>With the event able to go ahead in person again, we were able to return to our original format.
                        However, we decided to keep the virtual element running alongside the physical event, for
                        runners unable to make it to the venue. £5079 was raised (£5878.99 inc. Gift Aid), and for the
                        first time, we organised four walking waves alongside the main running event.</p>
                </div>
            </div>
            <div className="container-timeline right">
                <div className="content">
                    <h2>2022</h2>
                    <p>A new city centre venue, a visit from the Mayor, and a huge effort from volunteers and
                        participants helped us to raise £10,824.50 (£12,694.50 inc. Gift Aid) - almost as much as we had
                        raised in our first three events combined!</p>
                </div>
            </div>
            <div className="container-timeline left">
                <div className="content">
                    <h2>2023</h2>
                    <p>Incredible participation from a large variety of running clubs and individuals across Manchester
                        (and beyond) contributed to raising an astounding <b>£26,435.49</b> - funding 881 beds and more
                        than doubling the overall total raised, in a single year.</p>
                </div>
            </div>
            <div className="container-timeline right">
                <div className="content">
                    <h2>2024</h2>
                    <p>A new event and expanded team in Birmingham debuts, joining our cause to help support local organisations helping people off the streets - now in multiple cities across the UK.</p>
                </div>
            </div>
        </div>
    );
}

export default Timeline