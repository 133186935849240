import SiteNavbar from "./ts/components/SiteNavbar";
import Footer from "./ts/components/Footer";
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import HomePage from "./ts/home/HomePage";
import ReactGA from 'react-ga';
import { useEffect } from "react";
import {PressPage} from "./ts/press/PressPage";
import BirminghamEventPage from "./ts/events/birmingham/BirminghamEventPage";
import GalleryPage from "./ts/gallery/GalleryPage";
import AboutPage from "./ts/about/AboutPage";
import ContactPage from "./ts/contact/ContactPage";

//Google Analytics
const TRACKING_ID = "G-SVT3R0T3QX";
ReactGA.initialize(TRACKING_ID);

const NavLayout = () => {
    return (
        <>
            <SiteNavbar/>
            <Outlet/>
            <Footer/>
        </>
    );
}

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
      <>
          <BrowserRouter>
              <Routes>
                  <Route element={<NavLayout />} >
                      <Route index element={<HomePage/>} />
                      <Route path={"/home"} element={<HomePage/>} />
                      <Route path={"/press"} element={<PressPage />} />
                      <Route path={"/events/birmingham"} element={<BirminghamEventPage />} />
                      <Route path={"/gallery"} element={<GalleryPage />} />
                      <Route path={"/about-us"} element={<AboutPage />} />
                      <Route path={"/contact"} element={<ContactPage />} />
                  </Route>
              </Routes>
          </BrowserRouter>
      </>
  );
}

export default App;
